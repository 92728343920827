import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { yiaaiConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import SectionLoader from "../../../helpers/SectionLoader";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router";
import queryString from "query-string";
import YiaaiAdminHelmet from "../../../helpers/YiaaiAdminHelmet";
import { Link, useParams, useSearchParams } from "react-router-dom";
import SyllabusTopicCard from "../../../includes/yiaai/SyllabusTopicCard";
import AddNewTopicModal from "../../../includes/modals/AddNewTopicModal";

function DigitalSyllabusTopic() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [isLoading, setLoading] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [topic, setTopic] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");
	const [addLessonModal, setAddLessonModal] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pagination, setPagination] = useState({});
	const [searchterm, setSearchTerm] = useState("");
	const currentParams = Object.fromEntries([...searchParams]);
	const [isclassLoading, setClassLoading] = useState(false);

	const [orderNumber, setOrderNumber] = useState("");
	const [thumbnail, setThumbnail] = useState("");
	const [reload, setReload] = useState(false);
	const [video, setVideo] = useState("");
	const [title, setTitle] = useState("");
	const [publish, setPublish] = useState("");
	const [deccription, setDescription] = useState("");
	const [lessonOverview, setLessonOverview] = useState("");
	const location = useLocation();
	const { slug, id } = useParams();
	let { search } = location;
	const values = queryString.parse(search);
	let page = values.page;
	const {
		state: {
			user_data: { access_token },
		},
	} = useContext(Context);

	useEffect(() => {
		const setInitialSearch = () => {
			setCurrentPage(page ? parseInt(page) : 1);
		};
		if (page) {
			setInitialSearch();
		}
	}, [page]);

	const handlePageClick = (selectedPage) => {
		setCurrentPage(selectedPage + 1);
		setSearchParams({ ...searchParams, page: selectedPage + 1 });
	};

	//fetch topic data for table

	const fetchCampusData = () => {
		setLoading(true);
		yiaaiConfig
			.get(`free-courses/topics/${id}`, {
				params: {
					page: currentPage,
					q: searchterm,
				},

				headers: {
					Authorization: `Bearer ${access_token}`,
				},
			})
			.then((response) => {
				let { StatusCode, data, paginator_data } = response.data;
				if (StatusCode === 6000) {
					setTopic(data);
					setPagination(paginator_data);

					setLoading(false);
				} else if (StatusCode === 6001) {
					setTopic("");
				}
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	//fetch to box count of topic
	const fetchLessonOverview = () => {
		setLoading(true);
		yiaaiConfig
			.get(`free-courses/lesson-overview/${id}`, {
				params: {},

				headers: {
					Authorization: `Bearer ${access_token}`,
				},
			})
			.then((response) => {
				let { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setLessonOverview(data);

					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};
	useEffect(() => {
		fetchLessonOverview();
	}, [access_token, id]);
	useEffect(() => {
		fetchCampusData();
	}, [access_token, currentPage, searchterm, slug, reload]);

	const addTopic = () => {
		setClassLoading(true);
		const formData = new FormData();
		formData.append("name", title);
		formData.append("thumbnail", thumbnail);
		formData.append("order_id", orderNumber);
		formData.append("video_url", video);
		formData.append("is_publish", publish);
		formData.append("description", deccription);

		yiaaiConfig
			.post(`free-courses/add-topic/${id}/`, formData, {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
			})
			.then((response) => {
				let { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setClassLoading(false);
					setEditModal(false);
					fetchCampusData();
					fetchLessonOverview();
					setTitle("");
					setThumbnail("");
					setOrderNumber("");
					setVideo("");
					setPublish("");
					setDescription("");
					setErrorMessage("");
				} else {
					setErrorMessage(data.message);

					if (data.message === "Lesson added successfully") {
						setClassLoading(false);
						fetchCampusData();
						fetchLessonOverview();
						setTitle("");
						setThumbnail("");
						setOrderNumber("");
						setVideo("");
						setPublish("");
						setDescription("");
						setErrorMessage("");
						setAddLessonModal(false);
					}
				}
			})
			.catch((error) => {
				console.log(error);
				setClassLoading(false);
				setErrorMessage(
					"An unexpected error occurred. Please try again."
				);
			});
	};

	return (
		<>
			<YiaaiAdminHelmet title="Lessons" />
			<MainContainer id="main">
				<Container>
					<HeadingTop>{lessonOverview.name}</HeadingTop>
					<SubHead>
						Dashboard / {lessonOverview.free_course}/
						{lessonOverview.name}
					</SubHead>
					<BoxContainer>
						<Heading>Lesson Overview</Heading>
						<CardContainer>
							<InnerCard>
								<ImageContainer>
									<Logo
										src={
											require("../../../../assets/icons/filter-lines.svg")
												.default
										}
										alt="monitor"
									/>
								</ImageContainer>
								<Description>
									<h3>Order No : </h3>
									<span style={{ marginLeft: "5px" }}>
										{" "}
										{lessonOverview.order_id}
									</span>
								</Description>
							</InnerCard>
							<InnerCard>
								<ImageContainer>
									<Logo
										src={
											require("../../../../assets/icons/time.svg")
												.default
										}
										alt="monitor"
									/>
								</ImageContainer>
								<Description>
									<span>{lessonOverview.topic_count}</span>

									<h3>Topics</h3>
								</Description>
							</InnerCard>
							<InnerCard>
								<ImageContainer>
									<Logo
										src={
											require("../../../../assets/icons/publish-tick.svg")
												.default
										}
										alt="monitor"
									/>
								</ImageContainer>
								<Description>
									{lessonOverview.is_publish ? (
										<h3>Published</h3>
									) : (
										<h3>Not Published</h3>
									)}
								</Description>
							</InnerCard>
						</CardContainer>
						<Cover>
							<Head>
								<Heading>Topics</Heading>
							</Head>
							<CoverContainer>
								<InputContainer>
									<SearchName
										type="text"
										placeholder="Search Here"
										onChange={(e) => {
											setSearchTerm(e.target.value);
										}}
										value={searchterm}
									/>

									<SearchImage
										to={`?query=${searchterm}`}
										onClick={() =>
											setSearchParams({
												...currentParams,
												query: searchterm,
											})
										}
									>
										<img
											src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/search-icon.svg"
											alt="image"
										/>
									</SearchImage>
								</InputContainer>
								<ButtonDiv
									onClick={() => {
										setAddLessonModal(true);
									}}
								>
									<ButtonIcon>
										<img
											src={
												require("../../../../assets/images/plus.svg")
													.default
											}
										/>
									</ButtonIcon>
									<span>New Topic</span>
								</ButtonDiv>
								<AddNewTopicModal
									mode="add"
									addModal={addLessonModal}
									addTopic={addTopic}
									setAddModal={setAddLessonModal}
									thumbnail={thumbnail}
									setThumbnail={setThumbnail}
									setVideo={setVideo}
									video={video}
									orderNumber={orderNumber}
									setOrderNumber={setOrderNumber}
									deccription={deccription}
									setDescription={setDescription}
									title={title}
									setTitle={setTitle}
									publish={publish}
									setPublish={setPublish}
									reload={reload}
									setReload={setReload}
									fetchLessonOverview={fetchLessonOverview}
									errorMessage={errorMessage}
									setErrorMessage={setErrorMessage}
								/>
							</CoverContainer>
						</Cover>
						<TableCover>
							<TableHeading>
								<Items>
									<Label type="slno">Order No.</Label>
									<Label type="name">Topic</Label>

									<Label type="count">Hide / Publish</Label>
									<Label type="action"></Label>
								</Items>
							</TableHeading>
							{isLoading ? (
								<SectionLoader />
							) : (
								<>
									{topic.length > 0 ? (
										<>
											<UserCardCover>
												{topic.map((item, index) => (
													<SyllabusTopicCard
														key={index}
														index={index}
														topic={item}
														pagination={pagination}
														reload={reload}
														setReload={setReload}
													/>
												))}
											</UserCardCover>
										</>
									) : (
										<ImageBox>
											<CardImage
												src={
													"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/12-02-2022/emptycard.svg"
												}
												alt="Image"
											/>
										</ImageBox>
									)}
								</>
							)}

							{pagination && pagination.total_pages > 1 && (
								<PaginationContainer>
									<PaginationText>
										Showing lessons {pagination.first_item}{" "}
										- {pagination.last_item} of{" "}
										{pagination.total_items}
									</PaginationText>

									<ReactPaginate
										previousLabel={"< Previous"}
										nextLabel={"Next >"}
										breakLabel={"..."}
										breakClassName={"break-me"}
										pageCount={pagination.total_pages}
										marginPagesDisplayed={2}
										pageRangeDisplayed={5}
										onPageChange={({ selected }) =>
											handlePageClick(selected)
										}
										previousLinkClassName={
											"pagination__link"
										}
										nextLinkClassName={"pagination__link"}
										disabledClassName={
											"pagination__link--disabled"
										}
										activeClassName={
											"pagination__link--active"
										}
										containerClassName={"pagination"}
									/>
								</PaginationContainer>
							)}
						</TableCover>
					</BoxContainer>
				</Container>
			</MainContainer>
		</>
	);
}

export default DigitalSyllabusTopic;

const MainContainer = styled.div`
	color: #fff;
`;
const Container = styled.div`
	overflow-x: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;
const BoxContainer = styled.div`
	min-width: 1280px;
`;

const Head = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 15px;
`;
const Heading = styled.h3`
	font-family: "gordita_medium";
	font-size: 20px;
	margin-bottom: 10px;
`;
const TableCover = styled.div`
	background-color: #1b1c1f;
	padding: 20px 20px 0;
	border-radius: 5px;
	overflow-x: scroll;
	&::-webkit-scrollbar {
		display: none;
	}
`;

const HeadingTop = styled.h3`
	color: #fff;
	font-family: "gordita_medium";
	font-size: 24px;
	margin-bottom: 10px;
`;

const SubHead = styled.h5`
	color: #b7b7b7;
	font-size: 14px;
	font-family: "gordita_regular";
	margin-bottom: 30px;
`;
const TableHeading = styled.div`
	min-width: 1200px;
`;
const CardContainer = styled.ul`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #1b1c21;
	border: 1px solid #2c2c2c;
	border-radius: 5px;
	padding: 20px;
	margin-bottom: 20px;
	font-family: "gordita_regular";
	:hover {
		opacity: 0.85;
		cursor: ${({ type }) =>
			type === "not_active" ? "not-allowed" : "pointer"};
	}
`;
const InnerCard = styled.div`
	align-items: center;
	display: flex;
`;

const ImageContainer = styled.span`
	margin-right: 10px;
	width: 25px;
`;

const Logo = styled.img`
	width: 100%;
	display: block;
`;

const Description = styled.div`
	color: #fff;
	display: flex;
	align-items: center;
	h3 {
		color: #e4e4e4;
		font-size: 16px;
		font-family: "gordita_medium";
	}
	span {
		color: #e4e4e4;
		font-size: 18px;
		font-family: "gordita_medium";
		margin-right: 5px;
	}
`;

const Items = styled.ul`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
`;
const Label = styled.li`
	width: 10%;
	text-align: center;
	color: #b1b1b1;
	font-family: "gordita_medium";
	width: ${(props) =>
		props.type === "slno"
			? "9%"
			: props.type === "topic"
			? "12%"
			: props.type === "public"
			? "12%"
			: props.type === "action"
			? "16%"
			: props.type === "name"
			? "60%"
			: "10%"};

	text-align: ${(props) =>
		props.type === "action"
			? "right"
			: props.type === "count" || props.type === "date"
			? "center"
			: props.type === "slno" || props.type === "name"
			? "left"
			: "left"};
`;
const UserCardCover = styled.div`
	padding: 20px 0 0;
`;
const CoverContainer = styled.div`
	margin-bottom: 15px;
	width: 36%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const InputContainer = styled.div`
	border-radius: 5px;
	background: #161619;
	width: 100%;
	height: 40px;
	margin-right: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #272729;
	padding: 0 10px;
`;
const SearchImage = styled(Link)`
	width: 5%;
	object-fit: contain;
	margin-right: 5px;
	img {
		width: 100%;
		display: block;
	}
`;
const SearchName = styled.input`
	font-size: 14px;
	font-family: "gordita_regular";
	height: 40px;
	width: 80%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #fff;
	::placeholder {
		font-family: "gordita_regular";
		font-size: 14px;
	}
`;
const ImageBox = styled.div`
	margin: 0 auto;
	width: 20%;
	margin-top: 100px;
	height: calc(100% - (-50px));
	@media all and (max-width: 980px) {
		width: 200px;
	}
`;
const CardImage = styled.img`
	width: 100%;
	display: block;
`;
const PaginationContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 40px 0 15px;
	@media all and (max-width: 890px) {
		flex-direction: column;
	}
`;
const PaginationText = styled.p`
	color: #898989;
	font-size: 14px;
	@media all and (max-width: 890px) {
		margin-bottom: 10px;
	}
`;
const Cover = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ButtonDiv = styled.div`
	width: 250px;
	position: relative;
	height: 40px;
	border-radius: 5px;
	background: #cf5633;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-right: 20px;
	span {
		font-size: 14px;
	}
`;
const ButtonIcon = styled.div`
	width: 15px;
	margin-right: 10px;
	img {
		width: 100%;
		display: block;
	}
`;
