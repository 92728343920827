import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import SectionLoader from "../../../helpers/SectionLoader";

import Basic from "../screens/Basic";
import Syllabus from "../screens/Syllabus";
import Enrollments from "../screens/Enrollments";
import Assessments from "../screens/Assessments";

function DigitalHeader() {
	const navigate = useNavigate();
	const { slug } = useParams();
	const [searchParams] = useSearchParams();
	const type = searchParams.get("type");

	const getActiveTab = (type) => {
		switch (type) {
			case "basic":
				return 1;
			case "syllabus":
				return 2;
			case "enrollment":
				return 3;
			case "assessment":
				return 4;
			default:
				return 1;
		}
	};

	const [activeTab, setActiveTab] = useState(getActiveTab(type));
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setActiveTab(getActiveTab(type));
	}, [type]);

	const TabContent1 = () => <Basic />;
	const TabContent2 = () => <Syllabus />;
	const TabContent3 = () => <Enrollments />;
	const TabContent4 = () => <Assessments />;

	const handleTabClick = (newType) => {
		navigate(`/dashboard/${slug}?type=${newType}`);
	};

	return (
		<>
			<MainContainer>
				<TableContainer>
					<TabHeading
						active={activeTab === 1}
						onClick={() => handleTabClick("basic")}
					>
						Basic Info
					</TabHeading>
					<TabHeading
						active={activeTab === 2}
						onClick={() => handleTabClick("syllabus")}
					>
						Syllabus
					</TabHeading>
					<TabHeading
						active={activeTab === 3}
						onClick={() => handleTabClick("enrollment")}
					>
						Enrollment
					</TabHeading>
					<TabHeading
						active={activeTab === 4}
						onClick={() => handleTabClick("assessment")}
					>
						Assessment
					</TabHeading>
				</TableContainer>
				{loading ? (
					<SectionLoader />
				) : activeTab === 1 ? (
					<TabContent1 />
				) : activeTab === 2 ? (
					<TabContent2 />
				) : activeTab === 3 ? (
					<TabContent3 />
				) : (
					<TabContent4 />
				)}
			</MainContainer>
		</>
	);
}

export default DigitalHeader;

const MainContainer = styled.div`
	width: 100%;
	color: white;
`;

const TabHeading = styled.h3`
	font-size: 14px;
	margin-right: 18px;
	padding-bottom: 4px;
	font-family: "gordita_medium";

	color: ${(props) => (props.active ? "#fe673a" : "#667085")};
	border-bottom: ${(props) => (props.active ? "2px solid #fe673a" : "none")};
	cursor: pointer;
`;
const TableContainer = styled.div`
	display: flex;
	border-bottom: 1px solid #26272a;
	margin-bottom: 28px;
`;
