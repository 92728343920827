import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import { Context } from "../../contexts/Store";
import { yiaaiConfig } from "../../../axiosConfig";
import SectionLoader from "../../helpers/SectionLoader";

import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import dot from "../../../assets/icons/dot.svg";
import dotGreen from "../../../assets/icons/dot-green.svg";
import upArrow from "../../../assets/icons/up-arrow.svg";
import downArrow from "../../../assets/icons/down-arrow.svg";
import ResetPasswordConformationModal from "../../includes/modals/RestPasswordConformationModal";
export default function UsersSinglePage() {
	const {
		state: {
			user_data: { access_token },
		},
		dispatch,
	} = useContext(Context);
	const { student_id } = useParams();

	const [programs, setPrograms] = useState([]);
	const [certificateId, setCertificateID] = useState("");
	const [studentDetails, setStudentDetails] = useState({});
	const [studentLoading, setStudentLoading] = useState(false);
	const [isBottomDivVisible, setIsBottomDivVisible] = useState(false);
	const [isEdit, setEdit] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [watchprofileData, setWatchprofileData] = useState([]);
	const [dayloading, setDayloading] = useState(false);
	const [resetModal, setResetModal] = useState(false);
	const [resetConfirmModal, setResetConfirmModal] = useState(false);
	const [isClassLoading, setClassLoading] = useState(false);
	const [passwordData, setPasswordData] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [entrollments, setEntrollments] = useState("");
	const [visibleIndex, setVisibleIndex] = useState(null);
	const [enrolled, setEnrolled] = useState();
	const { slug, id } = useParams();

	const CloseModal = () => {
		setResetConfirmModal(!resetConfirmModal);
	};
	const toggleVisibility = (index) => {
		setVisibleIndex(visibleIndex === index ? null : index);
	};

	//fetch user data
	useEffect(() => {
		const fetchEntrollmentProfileData = () => {
			setLoading(true);
			yiaaiConfig
				.get(`free-courses/user/${id}`, {
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				})
				.then((response) => {
					let { StatusCode, data } = response.data;
					if (StatusCode === 6000) {
						setEntrollments(data);
					}
					setLoading(false);
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		};
		fetchEntrollmentProfileData();
	}, [access_token, id]);

	//fetch watch profile data

	useEffect(() => {
		const fetchWatchProfileData = () => {
			setLoading(true);
			yiaaiConfig
				.get(`free-courses/user-list/${id}`, {
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				})
				.then((response) => {
					let { StatusCode, data } = response.data;
					if (StatusCode === 6000) {
						setWatchprofileData(data);
					}
					setLoading(false);
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		};
		fetchWatchProfileData();
	}, [access_token, id]);
	//fetch entrollment data

	const fetchPrograms = () => {
		setLoading(true);
		yiaaiConfig
			.get(`free-courses/programs-enrolled/${enrolled}/`, {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},

				params: {},
			})
			.then((response) => {
				let { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setPrograms(data);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};
	// useEffect(() => {
	// 	fetchPrograms();
	// }, [access_token, enrolled]);
	useEffect(() => {
		if (enrolled) {
			fetchPrograms();
		}
	}, [access_token, enrolled]);

	//fetch download certificate data

	const downloadCertificate = () => {
		setLoading(true);
		yiaaiConfig
			.get(`certifications/download-certificate/${certificateId}/`, {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
				responseType: "blob",
				onDownloadProgress: (progressEvent) => {
					const percentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					);
				},
			})
			.then((response) => {
				const url = window.URL.createObjectURL(
					new Blob([response.data])
				);
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "certificate.pdf");
				document.body.appendChild(link);
				link.click();
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	const ResetPassword = () => {
		setClassLoading(true);
		yiaaiConfig
			.post(
				`accounts/chief-reset-password/${id}/`,
				{
					password: passwordData,
				},
				{
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				}
			)
			.then((response) => {
				const { StatusCode, data } = response.data;

				if (StatusCode === 6000) {
					setPasswordData(data?.password);
					setClassLoading(false);
					CloseModal();
					setPasswordData("");

					setErrorMessage("");
				} else {
					setErrorMessage(data.message);
					setClassLoading(false);

					if (errorMessage === "Password updated successfully") {
						setPasswordData("");
						setErrorMessage("");
					}
				}
			})
			.catch((error) => {
				console.error(error);
				setClassLoading(false);
			});
	};

	return (
		<>
			<div id="main">
				<Heading>Profile Details</Heading>
				<SubHead>
					Dashboard / Users /{" "}
					{entrollments?.name ? entrollments?.name : "--"}
				</SubHead>

				{studentLoading ? (
					<SectionLoader />
				) : (
					<Container>
						<ResetPasswordConformationModal
							resetConfirmModal={resetConfirmModal}
							setResetConfirmModal={setResetConfirmModal}
							passwordData={passwordData}
							setPasswordData={setPasswordData}
							ResetPasswordApi={ResetPassword}
							// name={user.student_name}
							errorMessage={errorMessage}
							resetModal={resetModal}
							setResetModal={setResetModal}
						/>
						<TopCover>
							<Cover>
								<TopSection>
									<LeftName>
										{/* <ProfilePhoto>
											<Profile
												src={
													require("../../../assets/icons/profile-avatar.svg")
														.default
												}
												alt="image"
											/>
										</ProfilePhoto> */}
										<ProfileId>
											<StudentName>
												{" "}
												{entrollments?.name
													? entrollments?.name
													: "--"}
											</StudentName>
											<StudentID>
												# {entrollments.user_id}
											</StudentID>
										</ProfileId>
										<OwnerButton>Owner</OwnerButton>
									</LeftName>
									<Card>
										<Namelabel>Mobile Number</Namelabel>

										<Name>
											{entrollments.country
												? entrollments.country
												: ""}
											{entrollments.phone
												? entrollments.phone
												: ""}
										</Name>
									</Card>
									<Card>
										<Namelabel>Date joined</Namelabel>

										<Name>
											{entrollments.date_added
												? entrollments.date_added
												: "--"}
										</Name>
									</Card>
									<Card>
										<Namelabel>Country</Namelabel>

										<Name>
											{entrollments.country_name
												? entrollments.country_name
												: "--"}
										</Name>
									</Card>
									<ButtonContainer>
										{!dayloading ? (
											<Createdays
												onClick={(e) => {
													e.preventDefault();
													setResetConfirmModal(true);
												}}
											>
												Reset Password
											</Createdays>
										) : (
											<Createdays className="create">
												<ButtonLoader />
											</Createdays>
										)}
									</ButtonContainer>
								</TopSection>
							</Cover>
						</TopCover>
						<Profiles>
							{watchprofileData.map((watchdata, index) => (
								<TopCover key={index}>
									<Cover>
										<TopSection>
											<LeftName>
												<ProfilePhoto>
													<Profile
														src={watchdata.image}
														alt="image"
													/>
												</ProfilePhoto>
												<ProfileId>
													<StudentName>
														{watchdata.name}
													</StudentName>
												</ProfileId>
											</LeftName>
											<ArrowIconContainer
												onClick={() => {
													toggleVisibility(index);
													setEnrolled(watchdata.id);
												}}
											>
												<ArrowIcon
													src={
														isBottomDivVisible
															? upArrow
															: downArrow
													}
													alt="toggle icon"
												/>
											</ArrowIconContainer>
										</TopSection>
										{visibleIndex === index && (
											<BottomDiv
												isVisible={
													visibleIndex === index
												}
											>
												<BottomHead>Info</BottomHead>
												<TopBottomSection>
													<Category>
														<Card>
															<Namelabel>
																Mobile Number
															</Namelabel>

															<Name>
																{
																	watchdata.phone
																}
															</Name>
														</Card>
														<Card>
															<Namelabel>
																Date joined
															</Namelabel>
															<Name>
																{
																	watchdata.date_added
																}
															</Name>
														</Card>
													</Category>
													<Category>
														<Card>
															<Namelabel>
																District
															</Namelabel>
															<Name>
																{watchdata.district
																	? watchdata.district
																	: "--"}
															</Name>
														</Card>
														<Card>
															<Namelabel>
																Local Body
															</Namelabel>
															<Name>
																{watchdata.localbody
																	? watchdata.localbody
																	: "--"}
															</Name>
														</Card>
														<Card>
															<Namelabel>
																Ward
															</Namelabel>
															<Name>
																{watchdata.ward
																	? watchdata.ward
																	: "--"}
															</Name>
														</Card>
													</Category>
													<Category>
														{/* <Card>
															<Namelabel>
																Campus Name
															</Namelabel>{" "}
															<Name>
															{watchdata.campus_name
																	? watchdata.campus_name
																	: "--"}
															</Name>
														</Card> */}
														<Card>
															<Namelabel>
																Campus Code
															</Namelabel>{" "}
															<Name>
																{watchdata.campus_name
																	? watchdata.campus_name
																	: "--"}
															</Name>
														</Card>
														<Card>
															<Namelabel>
																Department
															</Namelabel>{" "}
															<Name>
																{" "}
																{watchdata.department
																	? watchdata.department
																	: "--"}
															</Name>
														</Card>
													</Category>
													<Category>
														<Card>
															<Namelabel>
																Class
															</Namelabel>{" "}
															<Name>
																{" "}
																{watchdata.class_name
																	? watchdata.class_name
																	: "--"}
															</Name>
														</Card>
														<Card>
															<Namelabel>
																Divsion
															</Namelabel>{" "}
															<Name>
																{" "}
																{watchdata.division
																	? watchdata.division
																	: "--"}
															</Name>
														</Card>

														{/* <Card>
															<Namelabel>
																Institution
															</Namelabel>{" "}
															<Name>
																{" "}
																{watchdata.localbody
																	? watchdata.localbody
																	: "--"}
															</Name>
														</Card> */}
													</Category>
												</TopBottomSection>
												<BottomSectionContainer>
													<BottomHeading>
														Programs Enrolled
													</BottomHeading>

													<BoxContainer>
														{programs?.map(
															(program, index) =>
																program.is_completed ==
																false ? (
																	<Box>
																		<BoxTop>
																			<BoxTopLeft>
																				<BoxHeadIconContainer>
																					<BoxHeadIcon
																						src={
																							program.free_course ===
																							"tefun"
																								? require("../../../assets/icons/lightbulb.svg")
																										.default
																								: program.free_course ===
																								  "digital-and-finance"
																								? require("../../../assets/icons/Icon.svg")
																										.default
																								: require("../../../assets/icons/monitor.svg")
																										.default
																						}
																						alt="course icon"
																					/>
																				</BoxHeadIconContainer>

																				<BoxHead>
																					{program.free_course
																						? typeof program.free_course ===
																						  "string"
																							? program.free_course
																									.charAt(
																										0
																									)
																									.toUpperCase() +
																							  program.free_course.slice(
																									1
																							  )
																							: "Unexpected data type"
																						: "No course information"}
																				</BoxHead>
																			</BoxTopLeft>
																			<BoxTopRight>
																				<ProgressContainer>
																					<DotContainer>
																						<DotImage
																							src={
																								require("../../../assets/icons/dot.svg")
																									.default
																							}
																							alt="dot icon"
																						/>
																					</DotContainer>
																					<Progress>
																						In
																						Progress
																					</Progress>
																				</ProgressContainer>
																			</BoxTopRight>
																		</BoxTop>
																		<Card>
																			<Namelabel>
																				Enrolled
																				Date
																			</Namelabel>{" "}
																			<Name>
																				{
																					program.date_added
																				}
																			</Name>
																		</Card>
																		<Card>
																			<Namelabel>
																				Current
																				Topic
																			</Namelabel>{" "}
																			<Name>
																				{
																					program.current_topic
																				}
																			</Name>
																			<SubName>
																				{
																					program.current_lesson
																				}
																			</SubName>
																		</Card>
																	</Box>
																) : (
																	<Box>
																		<BoxTop>
																			<BoxTopLeft>
																				<BoxHeadIconContainer>
																					<BoxHeadIcon
																						src={
																							program.free_course ===
																							"tefun"
																								? require("../../../assets/icons/lightbulb.svg")
																										.default
																								: program.free_course ===
																								  "digital-and-finance"
																								? require("../../../assets/icons/Icon.svg")
																										.default
																								: require("../../../assets/icons/monitor.svg")
																										.default
																						}
																						alt="course icon"
																					/>
																				</BoxHeadIconContainer>

																				<BoxHead>
																					{program.free_course &&
																					typeof program.free_course ===
																						"string"
																						? program.free_course
																								.charAt(
																									0
																								)
																								.toUpperCase() +
																						  program.free_course.slice(
																								1
																						  )
																						: ""}
																				</BoxHead>
																			</BoxTopLeft>
																			<BoxTopRight>
																				<ProgressContainer>
																					<DotContainer>
																						<DotImage
																							src={
																								program.is_completed ===
																								false
																									? dot
																									: dotGreen
																							}
																							alt="dot icon"
																						/>
																					</DotContainer>
																					<Progress>
																						{program.is_completed ===
																						false
																							? "In Progress"
																							: "Completed"}
																					</Progress>
																				</ProgressContainer>
																			</BoxTopRight>
																		</BoxTop>
																		<BoxBottomCompleted>
																			<Card>
																				<Namelabel>
																					Enrolled
																					Date
																				</Namelabel>{" "}
																				<Name>
																					{
																						program.date_added
																					}
																				</Name>
																			</Card>
																			<Card>
																				<Namelabel>
																					Certificate
																					Status
																				</Namelabel>{" "}
																				<Name>
																					{program.is_downloaded ===
																					true
																						? "Downloaded"
																						: "Not Downloaded"}
																				</Name>
																			</Card>
																		</BoxBottomCompleted>
																		<DownloadButton
																			onClick={() => {
																				setCertificateID(
																					program.certificate_id
																				);
																				if (
																					certificateId
																				) {
																					downloadCertificate();
																				}
																			}}
																		>
																			<Download>
																				Download
																				Certificate
																			</Download>
																			<DownloadIconContainer>
																				<DownloadIcon
																					src={
																						require("../../../assets/icons/download.svg")
																							.default
																					}
																					alt="download icon"
																				/>
																			</DownloadIconContainer>
																		</DownloadButton>
																	</Box>
																)
														)}
													</BoxContainer>
												</BottomSectionContainer>
											</BottomDiv>
										)}
									</Cover>
								</TopCover>
							))}
						</Profiles>
					</Container>
				)}
			</div>
		</>
	);
}
const BottomDiv = styled.div`
	display: ${(props) => (props.isVisible ? "block" : "none")};
`;

const Profiles = styled.div`
	margin-bottom: 100px;
`;
const Container = styled.div``;

const TopCover = styled.div`
	padding: 25px;
	margin-bottom: 40px;
	border: 1px solid #26272a;
	border-radius: 5px;
	background: #1b1c1f;
	@media all and (max-width: 1350px) {
	}
	overflow-x: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;
const Cover = styled.div`
	min-width: 1100px;
`;
const ProgressContainer = styled.div`
	display: flex;
	align-items: center;
	background: #f9fafb;
	border: 1px solid #eaecf0;
	padding: 1px 5px;
	border-radius: 8px;
`;
const DotContainer = styled.div`
	width: 8px;
	margin-right: 4px;
`;
const DotImage = styled.img`
	display: block;
	width: 100%;
`;
const Progress = styled.h6`
	color: #344054;
	font-family: "gordita_regular";
	font-size: 12px;
`;
const TopSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 30px;
	margin-bottom: 20px;
`;
const LeftName = styled.div`
	display: flex;
	align-items: center;
	width: 30%;
`;
const ProfileId = styled.div`
	margin-right: 20px;
`;
const StudentName = styled.h6`
	font-size: 16px;
	color: #fff;
	font-family: gordita_medium;
`;
const ButtonContainer = styled.div`
	display: flex;
`;
const StudentID = styled.p`
	color: #f56939;
`;

const OwnerButton = styled.div`
	border: 1px solid #b2ddff;
	background: #eff8ff;
	color: #175cd3;
	padding: 4px 8px 4px 8px;
	border-radius: 8px;
	height: 26px;
	font-size: 12px;
`;
const BoxContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const BoxBottomCompleted = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const DownloadButton = styled.div`
	border: 1px solid #fe673a;
	border-radius: 6px;
	padding: 12px 12px 12px 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;
const Download = styled.h6`
	color: #fe673a;
	margin-right: 12px;
	font-size: 14px;
	font-weight: "gordita_bold";
`;

const DownloadIconContainer = styled.div`
	width: 18px;
`;

const DownloadIcon = styled.img`
	display: block;
	width: 100%;
`;

const Box = styled.div`
	border: 1px solid #2c2c2c;
	border-radius: 12px;
	padding: 24px;
	width: 32%;
`;
const BoxTop = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #3d3d3d;
	padding-bottom: 16px;
	margin-bottom: 20px;
`;
const BoxTopLeft = styled.div`
	display: flex;
	align-items: center;
`;
const BoxHeadIconContainer = styled.div`
	width: 20px;
	margin-right: 10px;
`;
const BoxHeadIcon = styled.img`
	width: 100%;
	display: block;
`;
const BoxHead = styled.h5`
	color: #ffffff;
	font-size: 18px;
	font-weight: "gordita_bold";
`;
const BoxTopRight = styled.div``;

const Heading = styled.h3`
	color: #fff;
	font-family: "gordita_medium";
	font-size: 24px;
	margin-bottom: 10px;
`;

const SubHead = styled.h5`
	color: #b7b7b7;
	font-size: 14px;
	font-family: "gordita_regular";
	margin-bottom: 26px;
`;

const TopBottomSection = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 30px;
	padding-bottom: 30px;
	margin-bottom: 20px;
	border-bottom: 1px solid #3d3d3d;
`;
const BottomSectionContainer = styled.div``;
const BottomHeading = styled.div`
	font-size: 16px;
	color: #fff;
	font-family: "gordita_medium";
	margin-bottom: 20px;
`;

const BottomHead = styled.div`
	font-size: 16px;
	color: #fff;
	font-family: "gordita_medium";
`;
const Category = styled.div`
	width: 25%;
`;
const Namelabel = styled.h6`
	font-family: gordita_medium;
	margin-bottom: 5px;
	font-size: 14px;
	color: #e8e8e899;
`;
const Name = styled.h5`
	color: #fff;
	font-size: 14px;

	text-transform: capitalize;
	&.lowercase {
		text-transform: lowercase;
	}
	/* height: 24px; */
`;
const SubName = styled.h5`
	color: #fff;
	font-size: 16px;
	font-family: "gordita_regular";

	/* height: 24px; */
`;

const ArrowIconContainer = styled.div``;
const ArrowIcon = styled.img``;

const Createdays = styled.div`
	font-family: gordita_medium;
	cursor: pointer;
	margin-right: 10px;
	background: #da5b36;
	color: #fff;
	border-radius: 5px;
	width: 135px;
	height: 40px;
	padding: 0 10px;
	justify-content: center;
	display: flex;
	font-size: 14px;
	align-items: center;

	&.create {
		width: 120px;
		height: 40px;
	}
`;

const Card = styled.div`
	margin-bottom: 20px;
	margin-right: 10px;
	position: relative;
	:last-child {
		margin-bottom: 0;
	}
`;

const ProfilePhoto = styled.div`
	width: 80px;
	margin-right: 20px;
`;
const Profile = styled.img`
	display: block;
	width: 100%;
	border-radius: 50%;
`;
