import React from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";

function DeleteModal({
	deleteModal,
	deleteApi,
	mode,
	setDeleteModal,
	errorMessage,
	setErrorMessage,
}) {
	return (
		<BackContainer style={{ transform: deleteModal && "scale(1,1)" }}>
			<Overlay
				onClick={() => setDeleteModal(false)}
				style={{ transform: deleteModal && "scale(1,1)" }}
			></Overlay>
			<Modal data-effect="mfp-zoom-in">
				<Conatiner>
					<HeadingContainer>
						<Heading>
							Delete{" "}
							{mode === "topic"
								? "Topic"
								: mode == "lesson"
								? "Lesson"
								: ""}
						</Heading>
						<Close>
							<ButtonClose
								onClick={(e) => {
									e.preventDefault();
									setDeleteModal(false);
								}}
							>
								<img
									src={
										require("../../../assets/images/close.svg")
											.default
									}
								/>
							</ButtonClose>
						</Close>
					</HeadingContainer>
					<BottomContainer>
						<Content>
							Are you sure you want to delete this{" "}
							{mode === "topic"
								? "topic"
								: mode == "lesson"
								? "lesson"
								: ""}
							? This action cannot be undone.{" "}
						</Content>
					</BottomContainer>
					{errorMessage && (
						<ErrorContainer>
							<ErrorMessage>{errorMessage}</ErrorMessage>
						</ErrorContainer>
					)}
					<Footer>
						<CancelButton
							onClick={(e) => {
								e.preventDefault();

								setDeleteModal(false);
							}}
							style={{ transform: deleteModal && "scale(1,1)" }}
						>
							Cancel
						</CancelButton>
						<DeleteButton
							onClick={(e) => {
								e.preventDefault();
								deleteApi();

								setDeleteModal(false);
							}}
						>
							Delete
						</DeleteButton>
					</Footer>
				</Conatiner>
			</Modal>
		</BackContainer>
	);
}

export default DeleteModal;
const Footer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;
const CancelButton = styled.div`
	color: #fe673a;
	border: 1px solid #fe673a;
	padding: 8px 18px 8px 18px;
	border-radius: 6px;
	margin-right: 10px;
`;
const DeleteButton = styled.div`
	color: #fe673a;
	background: #ffffff;
	border: 1px solid #ffffff;
	padding: 8px 18px 8px 18px;
	border-radius: 6px;
	cursor: pointer;
`;
const Content = styled.div`
	font-size: 16px;
	color: #ffffff;
	font-family: "gordita_regular";
`;
const BackContainer = styled.div`
	position: fixed;
	transition: 0.5s;
	transform: scale(0, 0);
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	min-height: 100vh;
	max-height: 100vh;
	filter: blur(1px);
	transform: scale(0);
`;
const Modal = styled.div`
	// width: 90%;
	width: 550px;
	max-width: 550px;
	// height: 52vh;
	height: ${({ textarea }) => (textarea ? "90vh" : "")};
	margin: 0 auto;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	transition: 0.5s;
	z-index: 101;
	border: 1px solid #3b3b3b;
	background: #1b1c20;
	border-radius: 5px;
	// overflow-y: scroll;
	-webkit-scrollbar {
		display: none;
	}
	box-shadow: 0px 3px 56px #00000048;

	@media all and (max-width: 980px) {
		width: 550px;
	}
	@media all and (max-width: 768px) {
		width: 400px;
	}
	@media all and (max-width: 640px) {
		width: 400px;
	}
	@media all and (max-width: 480px) {
		width: 340px;
	}
	@media all and (max-width: 360px) {
		width: 300px;
	}
`;
const ErrorContainer = styled.div`
	margin-top: 10px;
	color: red;
`;

const ErrorMessage = styled.div`
	color: red;
	font-family: "gordita_regular";
	font-size: 14px;
`;
const Conatiner = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px;
	width: 100%;
	height: 100%;
	padding-bottom: 50px;

	@media all and (max-width: 640px) {
		padding: 28px;
	}
`;
const HeadingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
`;
const Heading = styled.h3`
	font-family: "gordita_medium";
	font-size: 22px;
	color: #fff;
`;
const Close = styled.div`
	cursor: pointer;
`;
const ButtonClose = styled.div`
	width: 20px;
	img {
		display: block;
		width: 100%;
	}
`;
const MiddleContainer = styled.div`
	// padding: 20px 0 30px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 55px auto;
	p {
		color: #fff;
		font-family: "gordita_regular";
		font-size: 16px;
	}
`;
const TopMiddle = styled.div``;
const ClassDiv = styled.div`
	// display: flex;
	// align-items: center;
	// justify-content: space-between;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	// grid-template-rows: 50px 50px 50px;
	grid-gap: 25px;
	@media all and (max-width: 360px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
`;
const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const Span = styled.span`
	margin-right: 10px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
`;
const ClassHeading = styled.p`
	font-size: 14px;
	font-family: "gordita_regular";
	margin-bottom: 10px;
`;
const InputDiv = styled.div`
	height: 25px;
	width: 25px;
	border: 1px solid #3b3b3b;
	margin-right: 5px;
	border-radius: 5px;
	// cursor: pointer;
	background: #161619;
	cursor: ${({ is_added }) => (is_added ? "not-allowed" : "pointer")};
	& div {
		display: none;

		img {
			width: 100%;
			display: block;
		}
	}
	& div.selected {
		display: block;
	}
`;
const BottomContainer = styled.div`
	width: 100%;
	text-align: start;
	margin-bottom: 20px;

	@media all and (max-width: 480px) {
		justify-content: center;
	}
`;
const Button = styled(Link)`
	width: 30%;
	height: 50px;
	border: 1px solid #fe673a;
	border-radius: 5px;
	display: inline-block;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-family: "gordita_regular";
	color: #fff;
	:hover {
		background: #fe673a;
		color: #fff;
	}
	@media all and (max-width: 480px) {
		width: 40%;
		height: 40px;
	}
`;
