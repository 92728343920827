import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";

import { yiaaiConfig } from "../../../../axiosConfig";
import VideoPlayer from "../../../../components/applications/video-player/src/VideoPlayer";
import Blocks from "editorjs-blocks-react-renderer";
import { Link, useParams, useSearchParams } from "react-router-dom";

import { Context } from "../../../contexts/Store";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function ProjectOverView({}) {
	const { id } = useParams();
	const [topicOverview, setTopicOverview] = useState("");
	const [isLoading, setLoading] = useState(false);

	const {
		state: {
			user_data: { access_token },
		},
	} = useContext(Context);

	const fetchTopicOverview = () => {
		setLoading(true);
		yiaaiConfig

			.get(`free-courses/topic/${id}`, {
				params: {},

				headers: {
					Authorization: `Bearer ${access_token}`,
				},
			})
			.then((response) => {
				let { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setTopicOverview(data);

					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchTopicOverview();
	}, []);

	const details = [
		{
			cover_video_url: topicOverview.video_url,
			image: topicOverview.thumbnail,
			editorContent: "",
		},
	];

	const videoJsOptions = {
		autoplay: false,
		controls: false,
		sources: [
			{
				src: details?.cover_video_url,
				type: "video/m3u8",
			},
		],
	};

	return (
		<MainContainer id="main">
			<Container>
				<Heading>{topicOverview.name}</Heading>
				<SubHead>
					Dashboard / {topicOverview.course_name} /{" "}
					{topicOverview.lesson_name} / {topicOverview.name}
				</SubHead>
				<BottomContainer>
					<HeadingBottom>Topic Overview</HeadingBottom>
					<BottonLeft>
						<CardContainer>
							<InnerCard>
								<ImageContainer>
									<Logo
										src={
											require("../../../../assets/icons/filter-lines.svg")
												.default
										}
										alt="monitor"
									/>
								</ImageContainer>
								<Description>
									<h3>Order No :</h3>
									{""}{" "}
									<span style={{ marginLeft: "5px" }}>
										{topicOverview.order_id}
									</span>
								</Description>
							</InnerCard>
							<InnerCard>
								<ImageContainer>
									<Logo
										src={
											require("../../../../assets/icons/time.svg")
												.default
										}
										alt="monitor"
									/>
								</ImageContainer>
								<Description>
									<span>{topicOverview.duration}</span>
								</Description>
							</InnerCard>
							<InnerCard>
								<ImageContainer>
									<Logo
										src={
											require("../../../../assets/icons/publish-tick.svg")
												.default
										}
										alt="monitor"
									/>
								</ImageContainer>
								<Description>
									{topicOverview.is_publish === true ? (
										<h3>Published</h3>
									) : (
										<h3>Not Published</h3>
									)}
								</Description>
							</InnerCard>
						</CardContainer>
						<BottomVideoContainer>
							<div>
								<VideoHead>Topic Video</VideoHead>
								<VideoSection>
									<VideoPlayer
										cover={details?.image}
										source={
											topicOverview.video_url
												? topicOverview.video_url
												: null
										}
										{...videoJsOptions}
										autoPlay={false}
									/>

									<LazyLoadImage
										src={details?.image}
										effect="blur"
									/>
								</VideoSection>
								<ThumbnailHead>Topic Thumbnail</ThumbnailHead>
								<ThumbnailImageContainer>
									<ThumbNailImage
										src={topicOverview.thumbnail}
										alt="thumb"
									/>
								</ThumbnailImageContainer>
								<DescriptionHead>Description</DescriptionHead>
								<TextSection>
									{topicOverview.description}
								</TextSection>
							</div>
						</BottomVideoContainer>
					</BottonLeft>
				</BottomContainer>
			</Container>
		</MainContainer>
	);
}

export default ProjectOverView;
const BottomVideoContainer = styled.div`
	margin: 0 auto;
	padding: 0 200px;
	width: 100%;
`;

const VideoHead = styled.h5`
	color: #fff;
	font-size: 14px;
	font-family: "gordita_regular";
	margin-bottom: 10px;
`;
const ThumbnailHead = styled.h5`
	color: #fff;
	font-size: 14px;
	font-family: "gordita_regular";
	margin-bottom: 10px;
`;
const ThumbnailImageContainer = styled.div`
	width: 30%;
	margin-bottom: 20px;
`;
const ThumbNailImage = styled.img`
	display: block;
	width: 100%;
`;
const DescriptionHead = styled.h5`
	color: #fff;
	font-size: 14px;
	font-family: "gordita_regular";
	margin-bottom: 10px;
`;
const MainContainer = styled.div``;
const Container = styled.div``;

const Heading = styled.h3`
	color: #fff;
	font-family: "gordita_medium";
	font-size: 24px;
	margin-bottom: 10px;
`;
const HeadingBottom = styled.h3`
	font-family: "gordita_medium";
	font-size: 20px;
	color: #ffff;
	margin-bottom: 12px;
`;

const SubHead = styled.h5`
	color: #b7b7b7;
	font-size: 14px;
	font-family: "gordita_regular";
	margin-bottom: 24px;
`;

const BottomContainer = styled.div`
	width: 100%;
`;

const BottonLeft = styled.div`
	width: 100%;
	padding: 24px;
	box-shadow: 0px 1px 2px 0px #1018280d;
	border: 1px solid #252525;
	border-radius: 12px;
`;

const VideoSection = styled.div`
	margin-bottom: 40px;
	width: 80%;

	img {
		display: block;
		width: 100%;
	}
`;
const TextSection = styled.div`
	margin-top: 10px;
	color: #fff;
	font-size: 12px;
	max-width: 750px;

	font-family: "gordita_regular";
`;

const CardContainer = styled.ul`
	display: flex;
	justify-content: space-between;
	align-items: center;

	border-bottom: 1px solid #2c2c2c;
	padding: 20px;
	margin-bottom: 20px;
	font-family: "gordita_regular";
	:hover {
		opacity: 0.85;
		cursor: ${({ type }) =>
			type === "not_active" ? "not-allowed" : "pointer"};
	}
`;
const InnerCard = styled.div`
	align-items: center;
	display: flex;
`;

const ImageContainer = styled.span`
	margin-right: 10px;
	width: 25px;
`;

const Logo = styled.img`
	width: 100%;
	display: block;
`;

const Description = styled.div`
	color: #fff;
	display: flex;
	align-items: center;
	h3 {
		color: #e4e4e4;
		font-size: 16px;
		font-family: "gordita_medium";
	}
	span {
		color: #e4e4e4;
		font-size: 18px;
		font-family: "gordita_medium";
		margin-right: 5px;
	}
`;
