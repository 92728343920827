import React from "react";
import styled from "styled-components";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Register the required components and the datalabels plugin
Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	color: #ffffff;
	padding: 4px;
	background-color: #1b1c1f;
	border-radius: 8px;
`;

const Title = styled.h2`
	font-size: 16px;
	margin: 10px 0 0 0;
	color: #ffffff;
`;

const Subtitle = styled.div`
	font-size: 14px;
	color: #e7e7e7;
	margin-bottom: 10px;
`;

const ChartContainer = styled.div`
	position: relative;
	width: 220px;
	height: 220px;
	margin-bottom: 20px;
`;

const Percentage = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 24px;
	font-weight: bold;
	color: #ffffff;
	text-align: center;
`;

const CompletionRate = ({
	completionRate,
	total_students,
	CompletionRatePercentage,
	course_completed,
}) => {
	const data = {
		datasets: [
			{
				data: [completionRate, total_students - completionRate],
				backgroundColor: ["#FE673A", "#d6d6d6"],
				borderWidth: 0,
			},
		],
	};

	const options = {
		cutout: "80%",
		plugins: {
			tooltip: { enabled: false },
			legend: { display: false },
			datalabels: {
				// Configure the datalabels plugin
				display: false,
				align: "center",
				anchor: "center",
				color: "#ffffff",
				font: {
					size: 16,
					weight: "bold",
				},
			},
		},
	};

	return (
		<Container>
			<Title>Completion Rate</Title>
			<Subtitle>Completion rate in last 30 days</Subtitle>
			<ChartContainer>
				<Doughnut data={data} options={options} />{" "}
				{/* Render the Doughnut chart with the configured data and options */}
				<Percentage>
					<div>{course_completed}</div>
					<div style={{ fontSize: "14px", color: "#00FF00" }}>
						{completionRate}%
					</div>
				</Percentage>
			</ChartContainer>
			<Subtitle>
				<span
					style={{
						width: "12px",
						height: "12px",
						borderRadius: "50%",
						background: "#FE673A",
						display: "inline-block",
						marginRight: "6px",
						// marginBottom: "20px",
					}}
				></span>
				Students
			</Subtitle>
		</Container>
	);
};

export default CompletionRate;
