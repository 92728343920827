import React, { useContext, useRef } from "react";
import styled from "styled-components";
import { useState } from "react";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

function DeleteQuestion({
	isDeleteModal,
	setDeleteModal,
	handledeleteQuestions,
	currentQuestionId,
}) {
	const [isLoading, setLoading] = useState(false);

	return (
		<BackContainer className="">
			<Cover className={isDeleteModal ? "active" : ""}>
				<Overlay
					onClick={() => {
						setDeleteModal(false);
					}}
				></Overlay>
				<Modal>
					<Container>
						<HeadingContainer>
							<Heading>Delete Question</Heading>
							<Close
								onClick={() => {
									setDeleteModal(false);
								}}
							>
								<CloseIcon
									src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
									alt="Close Icon"
								/>
							</Close>
						</HeadingContainer>
						<Text>
							Are you sure you want to delete this question ?
						</Text>
						<ButtonContainer>
							<Button
								className="reject"
								onClick={() => {
									setDeleteModal(false);
								}}
							>
								Cancel
							</Button>

							<Button
								onClick={() => {
									handledeleteQuestions();
								}}
							>
								{isLoading ? <ButtonLoader /> : "Yes"}
							</Button>
						</ButtonContainer>
					</Container>
				</Modal>
			</Cover>
		</BackContainer>
	);
}

export default DeleteQuestion;

const BackContainer = styled.div``;
const Heading = styled.div`
	color: #ffffff;
	font-family: "gordita_medium";
	font-size: 20px;
`;
const Cover = styled.div`
	position: fixed;
	transition: 0.3s;
	transform: scale(0, 0);
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	&.active {
		transform: scale(1, 1);
		backdrop-filter: blur(4px);
	}
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	min-height: 100vh;
	max-height: 100vh;
	filter: blur(1px);
	transform: scale(0);
`;
const Modal = styled.div`
	width: 90%;
	max-width: 460px;
	max-height: 100vh;
	position: absolute;
	margin: 0 auto;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: 0.5s;
	z-index: 1000;
	border-radius: 5px;
	overflow-y: hidden;
	box-shadow: 0px 3px 56px #00000048;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		display: none;
	}

	@media all and (max-width: 1280px) {
	}
	@media all and (max-width: 400px) {
		max-height: 70vh;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
	}
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 30px 35px;
	width: 100%;
	min-width: 100%;
	height: 100%;
	background-color: #1b1c1f;
	@media all and (max-width: 480px) {
		max-width: 430px;
		overflow-x: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
	}
	@media all and (max-width: 360px) {
		padding: 10px;
	}
`;
const HeadingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const Text = styled.p`
	font-size: 16px;
	margin-bottom: 20px;
	color: #ffffff;
	width: 100%;
	text-align: left;
	font-family: "Inter-Medium";
`;
const Close = styled.div`
	cursor: pointer;

	width: 20px;
`;
const CloseIcon = styled.img`
	width: 100%;
	display: block;
	margin-bottom: 15px;
`;
const Button = styled.div`
	font-size: 14px;

	height: 35px;
	width: 100px;
	color: #fe673a;
	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;
	background: #fff;
	border-radius: 8px;
	font-family: "gordita_medium";

	&:nth-child(1) {
		margin-right: 20px;
	}

	&.reject {
		background: #1b1c1f;
		color: #fe673a;
		border: 1px solid #fe673a;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	text-align: right;
	justify-content: flex-end;
`;
