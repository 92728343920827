import React, { useContext, useState } from "react";
import styled from "styled-components";
import { yiaaiConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";

function ResetModal({
	resetModal,
	setResetModal,
	passwordData,
	setPasswordData,
	ResetPasswordApi,
	resetConfirmModal,
	setResetConfirmModal,
	errorMessage,
	setErrorMessage,
}) {
	const {
		state: {
			user_data: { access_token },
		},
	} = useContext(Context);

	const handlePasswordChange = (e) => {
		setPasswordData(e.target.value);
	};

	const handleSave = () => {
		ResetPasswordApi({
			password: passwordData,
		});
		// setResetModal(false);
		// setResetConfirmModal(false);
		// setPasswordData("");
		// setErrorMessage("");
	};

	const handleCloseModal = () => {
		setResetConfirmModal(false);
		setResetModal(false);
		setPasswordData("");

		setErrorMessage("");
	};

	return (
		<BackContainer
			style={{ transform: resetModal ? "scale(1,1)" : "scale(0,0)" }}
		>
			<Overlay
				onClick={() => {
					setResetConfirmModal(false);
					setResetModal(false);
					setResetConfirmModal(false);
				}}
				style={{ transform: resetModal ? "scale(1,1)" : "scale(0,0)" }}
			></Overlay>
			<Modal
				onClick={(e) => e.stopPropagation()}
				data-effect="mfp-zoom-in"
			>
				<Container>
					<HeadingContainer>
						<Heading>Reset Password</Heading>
						<CloseButton onClick={handleCloseModal}>
							<img
								src={
									require("../../../assets/images/close.svg")
										.default
								}
							/>
						</CloseButton>
					</HeadingContainer>
					<InputsContainer>
						<InputWrapper>
							<Label>New Password</Label>
							<Input
								type="password"
								value={passwordData}
								onChange={handlePasswordChange}
								placeholder="Enter password"
							/>
						</InputWrapper>
					</InputsContainer>
					{errorMessage && (
						<ErrorContainer>
							<ErrorMessage>{errorMessage}</ErrorMessage>
						</ErrorContainer>
					)}
					<BottomContainer>
						<Button onClick={handleSave}>Confirm</Button>
					</BottomContainer>
				</Container>
			</Modal>
		</BackContainer>
	);
}

export default ResetModal;

// Styled components and other constants defined as before...

// Styled components
const BackContainer = styled.div`
	position: fixed;
	transition: 0.5s;
	transform: scale(0, 0);
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(2px);
`;

const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	min-height: 100vh;
	max-height: 100vh;
	filter: blur(1px);
	transform: scale(0);
`;
const ErrorContainer = styled.div`
	margin-top: 10px;
	color: red;
`;

const ErrorMessage = styled.div`
	color: red;
	font-family: "gordita_regular";
	font-size: 14px;
`;
const CloseButton = styled.div`
	width: 20px;
	img {
		display: block;
		width: 100%;
	}
`;

const Modal = styled.div`
	width: 550px;
	max-width: 550px;
	height: ${({ textarea }) => (textarea ? "90vh" : "")};
	margin: 0 auto;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	transition: 0.5s;
	z-index: 101;
	border: 1px solid #3b3b3b;
	background: #1b1c1f;
	border-radius: 5px;
	box-shadow: 0px 3px 56px #00000048;

	@media all and (max-width: 980px) {
		width: 550px;
	}
	@media all and (max-width: 768px) {
		width: 400px;
	}
	@media all and (max-width: 640px) {
		width: 400px;
	}
	@media all and (max-width: 480px) {
		width: 340px;
	}
	@media all and (max-width: 360px) {
		width: 300px;
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px;
	width: 100%;
	height: 100%;
	padding-bottom: 50px;

	@media all and (max-width: 640px) {
		padding: 28px;
	}
`;

const HeadingContainer = styled.div`
	border-bottom: 1px solid #3b3b3b;
	padding-bottom: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Heading = styled.h3`
	font-family: "gordita_medium";
	font-size: 22px;
	color: #fff;
`;

const InputsContainer = styled.div`
	margin-top: 20px;
`;

const InputWrapper = styled.div`
	margin-bottom: 20px;
	text-align: start;
`;

const Label = styled.label`
	color: #c3c3c3;
	font-family: "gordita_medium";
	font-size: 14px;
	margin-bottom: 5px;
	display: block;
`;

const Input = styled.input`
	width: 100%;
	height: 50px;
	border: 1px solid #3b3b3b;
	border-radius: 5px;
	padding: 0 10px;
	background: #161619;
	color: #fff;
`;

const BottomContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const Button = styled.div`
	width: 16%;
	height: 38px;
	border: 1px solid #fe673a;
	border-radius: 5px;
	display: inline-block;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background: #fe673a;
	font-family: "gordita_regular";
	color: #fff;

	@media all and (max-width: 480px) {
		/* width: 40%;
		height: 40px; */
	}
`;
