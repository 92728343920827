import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { yiaaiConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import SectionLoader from "../../../helpers/SectionLoader";
import DigitalSyllabusCard from "../../../includes/yiaai/SyllabusCard";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router";
import queryString from "query-string";
import YiaaiAdminHelmet from "../../../helpers/YiaaiAdminHelmet";
import { Link, useParams } from "react-router-dom";
import AddLessonModal from "../../../includes/modals/AddLessonModal";

function TefunCampus() {
	const [errorMessage, setErrorMessage] = useState("");
	const [lessonName, setLessonName] = useState("");
	const [orderNumber, setOrderNumber] = useState("");
	const [isActive, setIsActive] = useState(false);
	const [isclassLoading, setClassLoading] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [reload, setReload] = useState(false);
	const [syllabus, setSyllabus] = useState([]);
	const [pagination, setPagination] = useState(null);
	const [searchterm, setSearchTerm] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [addLessonModal, setAddLessonModal] = useState(false);
	const { slug } = useParams();
	const location = useLocation();
	let { search } = location;
	const values = queryString.parse(search);
	let page = values.page;
	const {
		state: {
			user_data: { access_token },
		},
	} = useContext(Context);

	useEffect(() => {
		// Set initial page from query params or default to page 1
		setCurrentPage(page ? parseInt(page) : 1);
	}, [page]);

	const fetchSyllabus = () => {
		setLoading(true);
		yiaaiConfig
			.get(`free-courses/lessons/${slug}`, {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
				params: {
					page: currentPage,
					q: searchterm,
				},
			})
			.then((response) => {
				const { StatusCode, paginator_data, data } = response.data;
				if (StatusCode === 6000) {
					setSyllabus(data);
					setPagination(paginator_data);
				} else if (StatusCode === 6001) {
					setSyllabus("");
				}
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};
	useEffect(() => {
		fetchSyllabus();
	}, [currentPage, searchterm, slug, reload]);

	const handlePageClick = ({ selected }) => {
		const newPage = selected + 1;
		setCurrentPage(newPage);
		setAddLessonModal(false);

		// Update URL query params
		const updatedParams = queryString.stringify({
			...queryString.parse(search),
			page: newPage,
		});
		window.history.replaceState(null, null, `?${updatedParams}`);
	};

	const addNewClass = () => {
		setClassLoading(true);
		yiaaiConfig
			.post(
				`free-courses/add-lesson/${slug}/`,
				{
					name: lessonName,
					order_id: orderNumber,
					is_publish: isActive,
				},
				{
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				}
			)
			.then((response) => {
				const { StatusCode, title, data } = response.data;
				if (StatusCode === 6000) {
					setErrorMessage(data.message);
					setClassLoading(false);
					setAddLessonModal(false);
					fetchSyllabus();
					setLessonName("");
					setOrderNumber("");
					setErrorMessage("");
				} else {
					setErrorMessage(data.message);
					if (errorMessage === "Lesson added successfully") {
						setClassLoading(false);
						fetchSyllabus();
						setLessonName("");
						setOrderNumber("");
						setAddLessonModal(false);
					}
				}
			})
			.catch((error) => {
				console.error(error);
				setClassLoading(false);
			});
	};

	return (
		<>
			<YiaaiAdminHelmet title="Lessons" />
			<MainContainer>
				<Container>
					<BoxContainer>
						<Cover>
							<Head>
								<Heading>Lessons</Heading>
							</Head>
							<CoverContainer>
								<InputContainer>
									<SearchName
										type="text"
										placeholder="Search Here"
										onChange={(e) => {
											setSearchTerm(e.target.value);
										}}
										value={searchterm}
									/>
									<SearchImage
										to={`?query=${searchterm}`}
										onClick={() => {
											handlePageClick({ selected: 0 });
										}}
									>
										<img
											src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/search-icon.svg"
											alt="Search"
										/>
									</SearchImage>
								</InputContainer>
								<ButtonDiv
									onClick={() => {
										setAddLessonModal(true);
									}}
								>
									<ButtonIcon>
										<img
											src={
												require("../../../../assets/images/plus.svg")
													.default
											}
											alt="Add Icon"
										/>
									</ButtonIcon>
									<span>New Lesson</span>
								</ButtonDiv>
								<AddLessonModal
									mode="add"
									addModal={addLessonModal}
									setAddModal={setAddLessonModal}
									addNewClass={addNewClass}
									lessonName={lessonName}
									setLessonName={setLessonName}
									orderNumber={orderNumber}
									setOrderNumber={setOrderNumber}
									isActive={isActive}
									setIsActive={setIsActive}
									errorMessage={errorMessage}
									setErrorMessage={setErrorMessage}
								/>
							</CoverContainer>
						</Cover>
						<TableCover>
							<TableHeading>
								<Items>
									<Label type="slno">Order No.</Label>
									<Label type="name">Name</Label>
									<Label>Topics</Label>
									<Label type="count">Hide / Publish</Label>
									<Label type="action"></Label>
								</Items>
							</TableHeading>
							{isLoading ? (
								<SectionLoader />
							) : (
								<>
									{syllabus && syllabus.length > 0 ? (
										<UserCardCover>
											{syllabus.map((item, index) => (
												<DigitalSyllabusCard
													key={index}
													index={index}
													syllabus={item}
													pagination={pagination}
													slug={slug}
													reload={reload}
													setReload={setReload}
													fetchSyllabus={
														fetchSyllabus
													}
												/>
											))}
										</UserCardCover>
									) : (
										<ImageBox>
											<CardImage
												src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/12-02-2022/emptycard.svg"
												alt="No Lessons"
											/>
										</ImageBox>
									)}
								</>
							)}
							{pagination && pagination.total_pages > 1 && (
								<PaginationContainer>
									<PaginationText>
										Showing lessons {pagination.first_item}{" "}
										- {pagination.last_item} of{" "}
										{pagination.total_items}
									</PaginationText>

									<ReactPaginate
										previousLabel={"< Previous"}
										nextLabel={"Next >"}
										breakLabel={"..."}
										breakClassName={"break-me"}
										pageCount={pagination.total_pages}
										marginPagesDisplayed={2}
										pageRangeDisplayed={5}
										onPageChange={handlePageClick}
										previousLinkClassName={
											"pagination__link"
										}
										nextLinkClassName={"pagination__link"}
										disabledClassName={
											"pagination__link--disabled"
										}
										activeClassName={
											"pagination__link--active"
										}
										containerClassName={"pagination"}
										forcePage={currentPage - 1}
									/>
								</PaginationContainer>
							)}
						</TableCover>
					</BoxContainer>
				</Container>
			</MainContainer>
		</>
	);
}

export default TefunCampus;

const MainContainer = styled.div`
	color: #fff;
`;
const Container = styled.div`
	overflow-x: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;
const BoxContainer = styled.div`
	min-width: 1280px;
`;

const Head = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 15px;
`;
const Heading = styled.h3`
	font-family: "gordita_medium";
	font-size: 20px;
`;
const TableCover = styled.div`
	background-color: #1b1c1f;
	padding: 20px 20px 0;
	border-radius: 5px;
	overflow-x: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;

const TableHeading = styled.div`
	min-width: 1200px;
`;

const Items = styled.ul`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
`;
const Label = styled.li`
	width: 10%;
	text-align: center;
	color: #b1b1b1;
	font-family: "gordita_medium";
	width: ${(props) =>
		props.type === "slno"
			? "9%"
			: props.type === "topic"
			? "12%"
			: props.type === "public"
			? "12%"
			: props.type === "action"
			? "16%"
			: props.type === "name"
			? "60%"
			: "10%"};

	text-align: ${(props) =>
		props.type === "action"
			? "right"
			: props.type === "count" || props.type === "date"
			? "center"
			: props.type === "slno" || props.type === "name"
			? "left"
			: "left"};
`;
const UserCardCover = styled.div`
	padding: 20px 0 0;
`;
const CoverContainer = styled.div`
	margin-bottom: 15px;
	width: 36%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const InputContainer = styled.div`
	border-radius: 5px;
	background: #161619;
	width: 100%;
	height: 40px;
	margin-right: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #272729;
	padding: 0 10px;
`;
const SearchImage = styled(Link)`
	width: 5%;
	object-fit: contain;
	margin-right: 5px;
	img {
		width: 100%;
		display: block;
	}
`;
const SearchName = styled.input`
	font-size: 14px;
	font-family: "gordita_regular";
	height: 40px;
	width: 80%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #fff;
	::placeholder {
		font-family: "gordita_regular";
		font-size: 14px;
	}
`;
const ImageBox = styled.div`
	margin: 0 auto;
	width: 20%;
	margin-top: 100px;
	height: calc(100% - (-50px));
	@media all and (max-width: 980px) {
		width: 200px;
	}
`;
const CardImage = styled.img`
	width: 100%;
	display: block;
`;
const PaginationContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 40px 0 15px;
	@media all and (max-width: 890px) {
		flex-direction: column;
	}
`;
const PaginationText = styled.p`
	color: #898989;
	font-size: 14px;
	@media all and (max-width: 890px) {
		margin-bottom: 10px;
	}
`;
const Cover = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const ButtonDiv = styled.div`
	width: 250px;
	position: relative;
	height: 40px;
	border-radius: 5px;
	background: #cf5633;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-right: 20px;
	span {
		font-size: 14px;
	}
`;
const ButtonIcon = styled.div`
	width: 15px;
	margin-right: 10px;
	img {
		width: 100%;
		display: block;
	}
`;
