import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";

import ListItem from "./ListItem";
import AddQuestionModal from "../../../includes/modals/AddQuestionModal";

import EditQuestionModal from "../../../includes/modals/EditQuestionModal";

import { Link, useParams, useSearchParams } from "react-router-dom";
import EditInstructions from "../../../includes/modals/EditInstructions";
import { yiaaiConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";

function DigitalAssessments() {
	const [addLessonModal, setAddLessonModal] = useState(false);

	const [isEditModal, setEditModal] = useState(false);
	const [isclassLoading, setClassLoading] = useState(false);
	const [editInstructionModal, setEditInstructionModal] = useState(false);
	const [isDeleteModal, setDeleteModal] = useState(false);
	const [AddModal, setAddModal] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [count, setCount] = useState("");
	const [assessment, setAssesment] = useState([]);

	const [searchParams] = useSearchParams();
	const subject = searchParams.get("subject");
	const category = searchParams.get("category");
	const [questionObject, setQuestionObject] = useState({});

	const [pageLoading, setPageLoageing] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);

	const [searchterm, setSearchTerm] = useState("");

	const [question, setQuestion] = useState("");

	const [optionsA, setOptionsA] = useState("");
	const [optionsB, setOptionsB] = useState("");
	const [optionsC, setOptionsC] = useState("");
	const [optionsD, setOptionsD] = useState("");
	const [correctOption, setCorrectOption] = useState("");
	const [assessmentDetail, setAssessmentDetail] = useState("");

	const [isMalayalam, setMalayalam] = useState(false);
	const [reload, setReload] = useState(false);
	const { slug, id } = useParams();
	const {
		state: {
			user_data: { access_token },
		},
	} = useContext(Context);

	useEffect(() => {
		AddModal || isEditModal || isDeleteModal
			? (document.body.style.overflow = "hidden")
			: (document.body.style.overflow = "unset");
	}, [AddModal, isEditModal, isDeleteModal]);

	//fetch assessment data

	const fetchAssessmentQuestion = () => {
		setLoading(true);
		yiaaiConfig
			.get(`free-courses/assessment/questions/${slug}`, {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
				params: {
					page: currentPage,
					q: searchterm,
				},
			})
			.then((response) => {
				let { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setAssesment(data);
				} else if (StatusCode === 6001) {
					setAssesment("");
				}
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};
	useEffect(() => {
		fetchAssessmentQuestion();
	}, [access_token, currentPage, searchterm, reload, slug]);

	//fetch instruction api

	useEffect(() => {
		const fetchAssessmentDetail = () => {
			setLoading(true);
			yiaaiConfig
				.get(`free-courses/assessment/details/${slug}`, {
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
					params: {},
				})
				.then((response) => {
					let { StatusCode, data } = response.data;
					if (StatusCode === 6000) {
						setAssessmentDetail(data);
					}
					setLoading(false);
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		};
		fetchAssessmentDetail();
	}, [access_token, currentPage, searchterm, slug]);

	//fetch assessment box count data

	useEffect(() => {
		const fetchAssessmentCount = () => {
			setLoading(true);
			yiaaiConfig
				.get(`free-courses/assessment/count/${slug}`, {
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
					params: {},
				})
				.then((response) => {
					let { StatusCode, data } = response.data;
					if (StatusCode === 6000) {
						setCount(data);
					}
					setLoading(false);
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		};
		fetchAssessmentCount();
	}, [access_token, currentPage, searchterm, slug]);

	//Add question api

	const addNewQuestion = () => {
		setClassLoading(true);
		yiaaiConfig
			.post(
				`free-courses/add-questions/${assessmentDetail.id}/`,
				{
					question: question,
					option1: optionsA,
					option2: optionsB,
					option3: optionsC,
					option4: optionsD,
					right_option: correctOption,
				},
				{
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				}
			)
			.then((response) => {
				let { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setClassLoading(false);
					setAddLessonModal(false);
					fetchAssessmentQuestion();
					setQuestion("");
					setOptionsA("");
					setOptionsB("");
					setOptionsC("");
					setOptionsD("");
					setCorrectOption("");
				} else {
					setClassLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				setClassLoading(false);
			});
	};

	return (
		<MainContainer>
			<Container>
				<Cover>
					<TopContainer>
						<Card>
							<LabelContainer>
								<Description>
									<h3>No of Questions</h3>
								</Description>
							</LabelContainer>
							<Count>{count.question_count}</Count>
						</Card>

						<Card>
							<LabelContainer>
								<Description>
									<h3>Total Submissions</h3>
								</Description>
							</LabelContainer>
							<Count>{count.total_submissions}</Count>
						</Card>

						<Card>
							<LabelContainer>
								<Description>
									<h3>Pending Submissions</h3>
								</Description>
							</LabelContainer>
							<Count>{count.pending_submissions}</Count>
						</Card>
					</TopContainer>
				</Cover>
				<BottomDiv>
					<MiniCover>
						<QuestionHead>Questions & Answers</QuestionHead>

						<Button
							onClick={() => {
								setAddModal(true);
							}}
						>
							<ImageDiv>
								<img
									src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/plus.svg"
									alt="AddButton"
								/>
							</ImageDiv>
							<Span>Add Question</Span>
						</Button>
					</MiniCover>
					<ListItem
						setQuestionObject={setQuestionObject}
						subject={subject}
						category={category}
						isEditModal={isEditModal}
						setEditModal={setEditModal}
						isDeleteModal={isDeleteModal}
						setDeleteModal={setDeleteModal}
						isMalayalam={isMalayalam}
						setMalayalam={setMalayalam}
						assessmentDetail={assessmentDetail}
						fetchAssessmentQuestion={fetchAssessmentQuestion}
						questions={assessment}
						setQuestions={setAssesment}
						pageLoading={pageLoading}
						reload={reload}
						setReload={setReload}
					/>
					<AddQuestionModal
						AddModal={AddModal}
						setAddModal={setAddModal}
						addNewQuestion={addNewQuestion}
						setPageLoageing={setPageLoageing}
						question={question}
						optionsA={optionsA}
						optionsB={optionsB}
						optionsC={optionsC}
						optionsD={optionsD}
						setOptionsA={setOptionsA}
						setOptionsB={setOptionsB}
						setOptionsC={setOptionsC}
						setOptionsD={setOptionsD}
						correctOption={correctOption}
						setCorrectOption={setCorrectOption}
						setQuestion={setQuestion}
					/>

					<EditInstructions
						AddModal={editInstructionModal}
						setAddModal={setEditInstructionModal}
						setPageLoageing={setPageLoageing}
					/>
				</BottomDiv>
			</Container>
		</MainContainer>
	);
}

export default DigitalAssessments;
const MainContainer = styled.section``;
const Container = styled.div``;
const Heading = styled.h3`
	color: #000;
	font-family: "Inter-Medium";
	font-size: 20px;
	display: block;
	margin-bottom: 10px;
`;

const QuestionHead = styled.h5`
	color: #ffffff;

	font-size: 20px;

	font-family: "gordita_medium";
`;

const BottomDiv = styled.div`
	margin: 0 auto;
	width: 70%;
`;
const Cover = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	margin-bottom: 20px;
	flex-wrap: wrap;

	@media all and (max-width: 480px) {
		margin-bottom: 10px;
	}
`;
const MiniCover = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	@media all and (max-width: 980px) {
		width: 100%;
		justify-content: flex-end;
	}
`;
const Button = styled.div`
	border: 1px solid #fe673a;
	color: #fff;
	display: flex;
	align-items: center;
	background-color: #fe673a;
	border-radius: 8px;
	padding: 8px 16px;
	cursor: pointer;
	justify-content: center;
	@media all and (max-width: 360px) {
		padding: 5px 10px;
	}
	&:hover {
	}
`;
const TopContainer = styled.div`
	min-width: 1100px;
	width: 100%;
	display: grid;
	grid-template-columns: ${({ id }) => (id ? "1fr 1fr 1fr" : "1fr 1fr 1fr")};
	grid-gap: 20px;
	margin-bottom: 30px;
`;
const Card = styled.div`
	background-color: #1b1c21;
	border: 1px solid #2c2c2c;
	border-radius: 5px;
	padding: 15px;
	font-family: "gordita_regular";
	:hover {
		opacity: 0.85;
		cursor: ${({ type }) =>
			type === "not_active" ? "not-allowed" : "pointer"};
	}
`;
const LabelContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
`;
const Count = styled.h5`
	color: #ffffff;
	font-size: 26px;
	font-weight: 600;
	margin-right: 10px;
`;

const Description = styled.div`
	color: #c9c9c9;
	display: flex;
	align-items: center;
	h3 {
		color: #c9c9c9;
		font-size: 18px;
		font-family: "gordita_regular";
	}
	span {
		color: #e4e4e4;
		font-size: 18px;
		font-family: "gordita_medium";
		margin-right: 5px;
	}
`;

const ImageDiv = styled.div`
	width: 20px;
	margin-right: 5px;
	img {
		width: 100%;
		display: block;
		color: #0fa76f;
	}
`;
const Span = styled.h5`
	font-size: 14px;
	color: #ffffff;
	font-family: "gordita_medium";
	@media all and (max-width: 480px) {
		font-size: 13px;
	}
	@media all and (max-width: 360px) {
		font-size: 12px;
	}
`;
