import React, { useState, useEffect, useContext } from "react";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import styled from "styled-components";
import { Context } from "../../../contexts/Store";
import { Link, useParams } from "react-router-dom";
import { yiaaiConfig } from "../../../../axiosConfig";
import DigitalCharts from "../includes/Charts";

function DigitalBasic({ isCountLoading, id, campusCount }) {
	const [isLoading, setLoading] = useState(false);
	const [basicDetails, setBasicDetails] = useState([]);
	const [linchartData, setLineChartData] = useState([]);
	const { slug } = useParams();

	const {
		state: {
			user_data: { access_token },
		},
	} = useContext(Context);
	useEffect(() => {
		const fetchDetails = () => {
			setLoading(true);
			yiaaiConfig
				.get(`campuses/total-students-count/${slug}`, {
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				})
				.then((response) => {
					let { StatusCode, data } = response.data;
					if (StatusCode === 6000) {
						setBasicDetails(data);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		};
		fetchDetails();
	}, []);

	useEffect(() => {
		const fetchLineChart = () => {
			setLoading(true);
			yiaaiConfig
				.get(`campuses/admissions-day-chart/${slug}`, {
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				})
				.then((response) => {
					let { StatusCode, data } = response.data;
					if (StatusCode === 6000) {
						setLineChartData(data.user_data);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		};
		fetchLineChart();
	}, []);

	return (
		<Container>
			<Head>Program Overview</Head>
			<div>
				<CardContainer>
					<InnerCard>
						<ImageContainer>
							<Logo
								src={
									require("../../../../assets/icons/learn.svg")
										.default
								}
								alt="monitor"
							/>
						</ImageContainer>
						<Description>
							{isCountLoading ? (
								<ButtonLoader />
							) : (
								<span>{basicDetails.total_lesson}</span>
							)}
							<h3>Lessons</h3>
						</Description>
					</InnerCard>
					<InnerCard>
						<ImageContainer>
							<Logo
								src={
									require("../../../../assets/icons/time.svg")
										.default
								}
								alt="monitor"
							/>
						</ImageContainer>
						<Description>
							{isCountLoading ? (
								<ButtonLoader />
							) : (
								<span>{basicDetails.course_duration}</span>
							)}
						</Description>
					</InnerCard>
					<InnerCard>
						<ImageContainer>
							<Logo
								src={
									require("../../../../assets/icons/time.svg")
										.default
								}
								alt="monitor"
							/>
						</ImageContainer>
						<Description>
							{isCountLoading ? (
								<ButtonLoader />
							) : (
								<span>{basicDetails.topic_count}</span>
							)}
							<h3>Videos</h3>
						</Description>
					</InnerCard>
					<InnerCard>
						<ImageContainer>
							<Logo
								src={
									require("../../../../assets/icons/video-recorder.svg")
										.default
								}
								alt="monitor"
							/>
						</ImageContainer>
						<Description>
							<h3>{basicDetails.institution_type}</h3>
						</Description>
					</InnerCard>
				</CardContainer>
			</div>
			<TopContainer id={id}>
				{id ? (
					<></>
				) : (
					<Card>
						<LabelContainer>
							<ImageContainerSmall>
								<Logo
									src={
										require("../../../../assets/icons/user.svg")
											.default
									}
									alt="monitor"
								/>
							</ImageContainerSmall>
							<Description>
								<h3>Associated Campuses</h3>
							</Description>
						</LabelContainer>
						<Count>{basicDetails.associated_campuses}</Count>
					</Card>
				)}
				{id ? (
					<></>
				) : (
					<Card>
						<LabelContainer>
							<ImageContainerSmall>
								<Logo
									src={
										require("../../../../assets/icons/user.svg")
											.default
									}
									alt="monitor"
								/>
							</ImageContainerSmall>
							<Description>
								<h3>Total Students</h3>
							</Description>
						</LabelContainer>
						<Count>{basicDetails.total_students}</Count>
					</Card>
				)}
				{id ? (
					<></>
				) : (
					<Card>
						<LabelContainer>
							<ImageContainerSmall>
								<Logo
									src={
										require("../../../../assets/icons/user.svg")
											.default
									}
									alt="monitor"
								/>
							</ImageContainerSmall>
							<Description>
								<h3>Enrolled Students</h3>
							</Description>
						</LabelContainer>
						<Count>{basicDetails.enrolled_students}</Count>
					</Card>
				)}
				{id ? (
					<></>
				) : (
					<Card>
						<LabelContainer>
							<ImageContainerSmall>
								<Logo
									src={
										require("../../../../assets/icons/user.svg")
											.default
									}
									alt="monitor"
								/>
							</ImageContainerSmall>
							<Description>
								<h3>Unenrolled Students</h3>
							</Description>
						</LabelContainer>
						<Count>{basicDetails.not_enrolled_students}</Count>
					</Card>
				)}
			</TopContainer>

			<DigitalCharts
				completion_rate={basicDetails.completion_rate}
				completion_rate_percentage={
					basicDetails.completion_rate_percentage
				}
				course_completed={basicDetails.course_completed}
				linchartData={linchartData}
				total_students={basicDetails.total_students}
			/>
		</Container>
	);
}

export default DigitalBasic;
const TopContainer = styled.div`
	min-width: 1100px;
	width: 100%;
	display: grid;
	grid-template-columns: ${({ id }) =>
		id ? "1fr 1fr 1fr 1fr" : "1fr 1fr 1fr 1fr "};
	grid-gap: 20px;
	margin-bottom: 30px;
`;
const Container = styled.div``;
const ImageContainerSmall = styled.div`
	width: 20px;
	margin-right: 10px;
`;
const LabelContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
`;
const Count = styled.h5`
	color: #ffffff;
	font-size: 26px;
	font-weight: 600;
	margin-right: 10px;
`;
const Head = styled.h5`
	color: #ffffff;
	font-size: 18px;
	font-family: "gordita_medium";
	margin-bottom: 12px;
`;
const CardContainer = styled.ul`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #1b1c21;
	border: 1px solid #2c2c2c;
	border-radius: 5px;
	padding: 20px;
	margin-bottom: 20px;
	font-family: "gordita_regular";
	:hover {
		opacity: 0.85;
		cursor: ${({ type }) =>
			type === "not_active" ? "not-allowed" : "pointer"};
	}
`;
const InnerCard = styled.li`
	align-items: center;
	display: flex;
`;
const Card = styled.div`
	background-color: #1b1c21;
	border: 1px solid #2c2c2c;
	border-radius: 5px;
	padding: 15px;
	font-family: "gordita_regular";
	:hover {
		opacity: 0.85;
		cursor: ${({ type }) =>
			type === "not_active" ? "not-allowed" : "pointer"};
	}
`;

const ImageContainer = styled.span`
	margin-right: 10px;
	width: 25px;
`;

const Logo = styled.img`
	width: 100%;
	display: block;
`;

const Description = styled.div`
	color: #fff;
	display: flex;
	align-items: center;
	h3 {
		color: #e4e4e4;
		font-size: 16px;
		font-family: "gordita_medium";
	}
	span {
		color: #e4e4e4;
		font-size: 18px;
		font-family: "gordita_medium";
		margin-right: 5px;
	}
`;
