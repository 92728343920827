import React, { useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function FilterModal({ setShowModal, onApply }) {
	const [startDate, setStartDate] = useState(null);
	const [onProgress, setOnProgress] = useState(false);
	const [completed, setCompleted] = useState(false);
	const toggleOnProgress = () => {
		setOnProgress(!onProgress);
	};
	const toggleCompleted = () => {
		setCompleted(!completed);
	};
	const handleApply = () => {
		onApply(startDate, onProgress, completed);
	};

	return (
		<ModalContent>
			<ModalHeader>
				<ModalTitle>Filter</ModalTitle>
				<CloseButton
					onClick={() => {
						setShowModal(false);
					}}
				>
					<CloseContainer>
						<Close
							src={
								require("../../../assets/images/close.svg")
									.default
							}
						/>
					</CloseContainer>
				</CloseButton>
			</ModalHeader>
			<ModalBody>
				<Label>Date of Enrollment</Label>
				<DateWrapper>
					<DatePicker
						selected={startDate}
						onChange={(date) => setStartDate(date)}
						placeholderText="Select Start Date"
						dateFormat="MM/dd/yyyy"
						isClearable
					/>
					<IconContainer>
						<Icon
							src={
								require("../../../assets/icons/calendar.svg")
									.default
							}
							alt="calendar icon"
						/>
					</IconContainer>
				</DateWrapper>
				<Label>Status</Label>
				<CheckboxContainer>
					<CheckboxLabel>
						On Progress
						<CheckboxImage
							src={
								onProgress
									? require("../../../assets/icons/checktick.svg")
											.default
									: require("../../../assets/icons/checkbox.svg")
											.default
							}
							alt="checkbox icon"
							onClick={toggleOnProgress}
						/>
					</CheckboxLabel>
					<CheckboxLabel>
						Completed
						<CheckboxImage
							src={
								completed
									? require("../../../assets/icons/checktick.svg")
											.default
									: require("../../../assets/icons/checkbox.svg")
											.default
							}
							alt="checkbox icon"
							onClick={toggleCompleted}
						/>
					</CheckboxLabel>
				</CheckboxContainer>
			</ModalBody>
			<ModalFooter>
				<ApplyButton onClick={handleApply}>Show Results</ApplyButton>
			</ModalFooter>
		</ModalContent>
	);
}

const ModalContent = styled.div`
	position: absolute;
	position: absolute;
	top: 195px;
	left: -35px;
	transform: translate(-50%, -50%);
	background-color: #1b1c1f;
	border: 1px solid #3d3d3d;
	padding: 20px;
	border-radius: 5px;
`;

const ModalHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
`;

const Icon = styled.img`
	display: block;
	width: 100%;
`;

const IconContainer = styled.div`
	width: 20px;
`;

const ModalTitle = styled.h3`
	font-size: 18px;
	font-family: "gordita_medium";
`;

const CloseButton = styled.button`
	background-color: transparent;
	border: none;
	font-size: 16px;
	color: #666;
	cursor: pointer;
`;

const ModalBody = styled.div`
	margin-bottom: 10px;
`;

const Label = styled.h6`
	margin-bottom: 8px;
	font-size: 14px;
	color: #c3c3c3;
	font-family: "gordita_medium";
`;

const DateWrapper = styled.div`
	.react-datepicker-wrapper {
		width: 100%;
	}
	.react-datepicker__input-container {
		width: 100%;
	}
	background: #161619;
	border: 1px solid #333333;
	border-radius: 6px;
	padding: 12px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
`;
const CloseContainer = styled.div`
	width: 12px;
`;
const Close = styled.img`
	display: block;
	width: 100%;
`;
const CheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const CheckboxLabel = styled.label`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	color: #c3c3c3;
	font-size: 12px;
`;

const CheckboxImage = styled.img`
	width: 14px;
	margin-left: 6px;
	cursor: pointer;
`;

const ModalFooter = styled.div`
	text-align: right;
`;

const ApplyButton = styled.button`
	background-color: #fe673a;
	color: #fff;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
`;

export default FilterModal;
