import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import DeleteModal from "../modals/DeleteModal";
import AddNewTopicModal from "../modals/AddNewTopicModal";
import { yiaaiConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";

function DigitalSyllabusTopicCard({
	topic,

	reload,
	setReload,
}) {
	const [isLoading, setLoading] = useState(false);
	const [isActive, setIsActive] = useState(topic.is_publish);
	const [deleteModal, setDeleteModal] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [orderNumber, setOrderNumber] = useState("");
	const [thumbnail, setThumbnail] = useState("");
	const [topicAddedData, setTopicAddedData] = useState("");
	const [video, setVideo] = useState("");
	const [title, setTitle] = useState("");
	const [publish, setPublish] = useState(false);
	const [deccription, setDescription] = useState("");
	const [isclassLoading, setClassLoading] = useState(false);

	const {
		state: {
			user_data: { access_token },
		},
	} = useContext(Context);

	//api for edit topic

	const addEditClass = () => {
		setClassLoading(true);
		const formData = new FormData();
		formData.append("name", title);

		if (thumbnail instanceof File || thumbnail instanceof Blob) {
			formData.append("thumbnail", thumbnail);
		}
		// formData.append("thumbnail", thumbnail);
		formData.append("order_id", orderNumber);
		formData.append("video_url", video);
		formData.append("is_publish", publish);
		formData.append("description", deccription);
		yiaaiConfig
			.post(`/free-courses/edit-topic/${topic.id}/`, formData, {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
			})
			.then((response) => {
				const { status_code, data } = response.data;

				if (status_code === 6000) {
					setEditModal(false);

					setReload(!reload);
					setClassLoading(false);
				} else {
				}
			})
			.catch((error) => {
				console.error(error);
				setClassLoading(false);
			});
	};
	//fetch already added topic to edit modal
	const fetchTopic = () => {
		setLoading(true);
		yiaaiConfig
			.get(`free-courses/topic/${topic.id}/`, {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
				params: {},
			})
			.then((response) => {
				let { StatusCode, data } = response.data;

				if (StatusCode === 6000) {
					setTopicAddedData(data);

					setThumbnail(data?.thumbnail);
					setTitle(data?.name);
					setVideo(data?.video_url);
					setDescription(data?.description);
					setOrderNumber(data?.order_id);
					setIsActive(data?.is_publish);
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	// api for toggle in list
	const handleToggleClick = (e) => {
		e.preventDefault();
		setIsActive((prevIsActive) => !prevIsActive);

		yiaaiConfig
			.post(
				`free-courses/topic-publish/${topic.id}/`,
				{
					is_publish: !isActive,
				},
				{
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				}
			)
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setIsActive(data.is_publish);
				} else {
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	// delete  topic api
	const deleteLesson = () => {
		setLoading(true);
		yiaaiConfig
			.post(
				`/free-courses/delete-topic/${topic.id}/`,
				{},
				{
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				}
			)
			.then((response) => {
				const { StatusCode } = response.data;
				if (StatusCode === 6000) {
					setDeleteModal(false);
					setReload(!reload);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	return (
		<MainContainer>
			<AddNewTopicModal
				setAddModal={setEditModal}
				addModal={editModal}
				mode="edit"
				addTopic={addEditClass}
				thumbnail={thumbnail}
				setThumbnail={setThumbnail}
				setVideo={setVideo}
				video={video}
				orderNumber={orderNumber}
				setOrderNumber={setOrderNumber}
				deccription={deccription}
				setDescription={setDescription}
				title={title}
				setTitle={setTitle}
				// publish={publish}
				// setPublish={setPublish}
				publish={isActive}
				setPublish={setIsActive}
				reload={reload}
				setReload={setReload}
			/>
			<DeleteModal
				deleteModal={deleteModal}
				setDeleteModal={setDeleteModal}
				deleteApi={deleteLesson}
				mode="topic"
			/>
			<Items to={`/syllabus/topic/${topic.id}`}>
				<ListItem type="slno">{topic.order_id}</ListItem>
				<ListItem type="topic">
					<ItemContainer>
						<VideoImageContainer>
							<VideoImage
								src={topic?.thumbnail}
								alt="thumbnail"
							/>
						</VideoImageContainer>
						<ItemRight>
							<Topic>{topic.name}</Topic>
							<VideoTime>{topic.duration}</VideoTime>
						</ItemRight>
					</ItemContainer>
				</ListItem>
				<ListItem type="public">
					<ToggleContainer onClick={handleToggleClick}>
						<ToggleButton isActive={isActive} />
					</ToggleContainer>
				</ListItem>
				<ListItem type="action">
					<IconContainer>
						<Icon
							src={
								require("../../../assets/icons/view.svg")
									.default
							}
							alt="icon"
						/>
					</IconContainer>
					<IconContainer
						onClick={(e) => {
							e.preventDefault();
							setEditModal(true);
							fetchTopic();
						}}
					>
						<Icon
							src={
								require("../../../assets/icons/edit.svg")
									.default
							}
							alt="icon"
						/>
					</IconContainer>

					<IconContainer
						onClick={(e) => {
							e.preventDefault();
							setDeleteModal(true);
						}}
					>
						<Icon
							src={
								require("../../../assets/icons/delete.svg")
									.default
							}
							alt="icon"
						/>
					</IconContainer>
				</ListItem>
			</Items>
		</MainContainer>
	);
}

export default DigitalSyllabusTopicCard;

const MainContainer = styled.div`
	min-width: 1200px;
`;

const Items = styled(Link)`
	border-top: 1px solid #161619;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0;
`;

const ListItem = styled.p`
	font-size: 14px;
	align-items: center;
	text-align: start;
	font-family: "gordita_regular";
	color: #fff;
	width: ${(props) =>
		props.type === "slno"
			? "9%"
			: props.type === "topic"
			? "60%"
			: props.type === "public"
			? "12%"
			: props.type === "action"
			? "10%"
			: "10%"};
	display: ${(props) => (props.type === "name" ? "flex" : "")};
	text-align: ${(props) =>
		props.type === "action"
			? "right"
			: props.type === "count" || props.type === "date"
			? "center"
			: ""};
	display: ${(props) => (props.type === "action" ? "flex" : "")};
	justify-content: ${(props) =>
		props.type === "action" ? "space-between" : ""};
	align-items: ${(props) => (props.type === "action" ? "center" : "")};
	span.action {
		padding: 6px 35px;
		border-radius: 5px;
		border: 1px solid #fe673a;
		font-family: "gordita_regular";
	}
`;

const ToggleContainer = styled.div`
	display: inline-block;
	cursor: pointer;
`;

const ToggleButton = styled.div`
	width: 40px;
	height: 25px;
	background-color: ${(props) => (props.isActive ? "#FE673A" : "#ccc")};
	border-radius: 15px;
	position: relative;
	transition: background-color 0.3s ease;

	&::after {
		content: "";
		width: 25px;
		height: 25px;
		background-color: white;
		border-radius: 50%;
		position: absolute;
		top: 0;
		left: ${(props) => (props.isActive ? "14px" : "0")};
		transition: left 0.3s ease;
	}
`;

const ItemContainer = styled.div`
	display: flex;
`;

const VideoImageContainer = styled.div`
	width: 12%;
	margin-right: 15px;
`;

const VideoImage = styled.img`
	display: block;
	width: 100%;
`;

const ItemRight = styled.div``;

const Topic = styled.div`
	font-size: 14px;
	color: #ffffff;
	font-weight: "gordita_medium";
`;

const VideoTime = styled.div``;

const IconContainer = styled.div`
	width: 20px;
`;

const Icon = styled.img`
	width: 100%;
	display: block;
`;
