import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { yiaaiConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import ResetPasswordConformationModal from "../../includes/modals/RestPasswordConformationModal";

function USersCard({ user, pagination, index }) {
	const [isActive, setIsActive] = useState(false);
	const [isClassLoading, setClassLoading] = useState(false);
	const [resetConfirmModal, setResetConfirmModal] = useState(false);
	const [passwordData, setPasswordData] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [resetModal, setResetModal] = useState(false);
	const {
		state: {
			user_data: { access_token },
		},
	} = useContext(Context);
	const handleToggleClick = (e) => {
		e.preventDefault();
		setIsActive(!isActive);
	};

	const CloseModal = () => {
		setResetConfirmModal(!resetConfirmModal);
	};
	//reset password api
	const ResetPassword = () => {
		setClassLoading(true);
		yiaaiConfig
			.post(
				`accounts/chief-reset-password/${user.id}/`,
				{
					password: passwordData,
				},
				{
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				}
			)
			.then((response) => {
				const { StatusCode, data } = response.data;

				if (StatusCode === 6000) {
					setPasswordData(data?.password);
					setClassLoading(false);
					CloseModal();
					setPasswordData("");

					setErrorMessage("");
				} else {
					setErrorMessage(data.message);
					setClassLoading(false);
					if (errorMessage === "Password updated successfully") {
						setPasswordData("");
						setErrorMessage("");
					}
				}
			})
			.catch((error) => {
				console.error(error);
				setClassLoading(false);
			});
	};

	return (
		<MainContainer>
			<ResetPasswordConformationModal
				resetConfirmModal={resetConfirmModal}
				setResetConfirmModal={setResetConfirmModal}
				passwordData={passwordData}
				setPasswordData={setPasswordData}
				ResetPasswordApi={ResetPassword}
				name={user.student_name}
				errorMessage={errorMessage}
				resetModal={resetModal}
				setResetModal={setResetModal}
			/>
			<Items to={`profiles/${user.id}`}>
				<ListItem type="slno">
					{pagination?.first_item + index}
				</ListItem>

				<ListItem type="profile">
					<ProfileContainer>
						{/* <AvatarContainer>
							<Avatar src={user.photo} />
						</AvatarContainer>{" "} */}
						<div>
							<Name>{user.name}</Name>
							<Phone>
								{" "}
								{user.country ? user.country : "--"}
								{user.phone ? user.phone : "--"}
							</Phone>
						</div>
					</ProfileContainer>
				</ListItem>

				<ListItem type="date">
					{user.date_added ? user.date_added : "--"}
				</ListItem>
				<ListItem type="reset">
					<Reset
						onClick={(e) => {
							e.preventDefault();
							setResetConfirmModal(true);
						}}
					>
						Reset
					</Reset>
				</ListItem>

				<ListItem type="view">
					<DotContainer>
						<DotImage
							src={
								require("../../../assets/icons/view.svg")
									.default
							}
							alt="dot icon"
						/>
					</DotContainer>
				</ListItem>
			</Items>
		</MainContainer>
	);
}

export default USersCard;
const ProfileContainer = styled.div`
	display: flex;
	align-items: center;
`;

const DotContainer = styled.div`
	width: 20px;
	margin-right: 4px;
`;
const DotImage = styled.img`
	display: block;
	width: 100%;
`;
const MainContainer = styled.div`
	min-width: 1200px;
`;
const Items = styled(Link)`
	border-top: 1px solid #161619;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 0;
`;
const ListItem = styled.p`
	font-size: 14px;
	align-items: center;
	text-align: start;
	font-family: "gordita_regular";
	color: #fff;
	width: ${(props) =>
		props.type === "slno"
			? "9%"
			: props.type === "profile"
			? "60%"
			: props.type === "date"
			? "26%"
			: props.type === "reset"
			? "12%"
			: props.type === "view"
			? "5%"
			: "10%"};
	display: ${(props) => (props.type === "name" ? "flex" : "")};
	text-align: ${(props) =>
		props.type === "action"
			? "right"
			: props.type === "count" || props.type === "date"
			? "center"
			: ""};
	display: ${(props) => (props.type === "action" ? "flex" : "")};
	justify-content: ${(props) =>
		props.type === "action" ? "space-between" : ""};
	align-items: ${(props) => (props.type === "action" ? "center" : "")};
	span.action {
		padding: 6px 35px;
		border-radius: 5px;
		border: 1px solid #fe673a;
		font-family: "gordita_regular";
	}
`;
const Reset = styled.div`
	color: #fe673a;
	font-size: 14px;
	font-family: "gordita_medium";
	width: fit-content;
	border-bottom: 1px solid #fe673a;
`;

const Name = styled.h6`
	font-size: 14px;
	font-family: "gordita_medium";
	color: #ffffff;
`;
const Phone = styled.h6`
	font-size: 14px;
	font-family: "gordita_regular";
	color: #ffffff;
`;
