import React from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";
import DigitalLineChart from "./LineChart";
import CompletionRate from "./CompletionRate";

function DigitalCharts({
	total_students,
	linchartData,
	completion_rate_percentage,
	completion_rate,
	course_completed,
}) {
	return (
		<MainContainer>
			<Top>
				<Heading>Program Insights</Heading>
				{/* <DateCard>
					<Date> 2022</Date>

					<Icon
						src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/filter.svg"
						alt="Image"
						alt=""
						width={15}
					/>
				</DateCard> */}
			</Top>
			<Cover>
				<LineChartContainer>
					<LineCard>
						<DigitalLineChart linchartData={linchartData} />
					</LineCard>
				</LineChartContainer>
				<BarChartContainer>
					<Card>
						<TopSection>
							<div>
								<Heading>Completion Rate</Heading>
								<Title>Completion rate in last 30 days</Title>
							</div>
						</TopSection>
						<BottomSection>
							<CompletionRate
								completionRate={completion_rate}
								CompletionRatePercentage={
									completion_rate_percentage
								}
								course_completed={course_completed}
								total_students={total_students}
							/>
						</BottomSection>
					</Card>
				</BarChartContainer>
			</Cover>
		</MainContainer>
	);
}

export default DigitalCharts;

const MainContainer = styled.div`
	overflow-x: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;

const Cover = styled.div`
	width: 100%;
	margin: 30px 0;
	display: flex;
	align-items: start;
	justify-content: space-between;
`;
const LineChartContainer = styled.div`
	width: 71%;
`;
const BarChartContainer = styled.div`
	width: 28%;
`;
const Title = styled.p`
	color: #e8e8e8;
	font-size: 12px;
	font-family: "gordita_medium";
	margin-bottom: 10px;
`;

const BottomSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Heading = styled.h6`
	color: #fff;
	font-family: "gordita_medium";
	font-size: 18px;
`;
const LineCard = styled.div`
	border: 1px solid #2c2c2c;
	border-radius: 5px;
	background-color: #1b1c1f;
	padding: 10px 15px 10px 10px;
`;
const Card = styled.div`
	border: 1px solid #2c2c2c;
	border-radius: 5px;
	background-color: #1b1c1f;
	/* margin-top: 12px; */
	height: 452px;
	padding: 20px 20px 20px 25px;
`;
const TopSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Top = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	justify-content: space-between;
`;
